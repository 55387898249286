/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TelusUser } from '../models/TelusUser';
import type { UserTokenDetails } from '../models/UserTokenDetails';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserApiService {
    /**
     * Retrieve a list of all Telus users.
     * @returns any Successfully retrieved list of users
     * @throws ApiError
     */
    public static listUsers(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/users',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Add a new telus user to keycloak
     * @param type
     * @param requestBody
     * @returns TelusUser User created successfully
     * @throws ApiError
     */
    public static createUser(
        type: string,
        requestBody: TelusUser,
    ): CancelablePromise<TelusUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/users',
            query: {
                'type': type,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove Telus user account
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static deleteUser(
        requestBody: Array<string>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve detailed information about a specific Telus user.
     * @param userId
     * @returns TelusUser Successfully retrieved user details
     * @throws ApiError
     */
    public static getUser(
        userId: string,
    ): CancelablePromise<TelusUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/users/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Update details of an existing telus user.
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateUser1(
        userId: string,
        requestBody: TelusUser,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get user token details
     * @returns UserTokenDetails Received user token details successfully
     * @throws ApiError
     */
    public static userToken(): CancelablePromise<UserTokenDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/users/token',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * List user roles
     * @param type
     * @returns any Retrieve the list of roles
     * @throws ApiError
     */
    public static listRoles(
        type: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/users/roles',
            query: {
                'type': type,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Logout current user
     * @returns void
     * @throws ApiError
     */
    public static logout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/users/logout',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Unable to logout user.`,
            },
        });
    }
}
