/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AthonetEmmeEventHistoryItem = {
    properties: {
        timestamp: {
            type: 'string',
        },
        event: {
            type: 'string',
        },
        evt: {
            type: 'JsonNode',
        },
    },
} as const;
