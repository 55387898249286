/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimProfile = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        apnProfileId: {
            type: 'number',
            format: 'int64',
        },
        isDefault: {
            type: 'boolean',
        },
        callingPartyCategory: {
            type: 'string',
        },
        chargingCharacteristics: {
            type: 'number',
            format: 'int64',
        },
        csgList: {
            type: 'string',
        },
        defaultApnProfile: {
            type: 'string',
        },
        exceptForPlmns: {
            type: 'string',
        },
        extendedUeAmbrDl: {
            type: 'boolean',
        },
        extendedUeAmbrUl: {
            type: 'boolean',
        },
        namOverS6a: {
            type: 'string',
        },
        namOverS6dGr: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        ratFrequencySelectionPriorityId: {
            type: 'number',
            format: 'int32',
        },
        regionalSubscriptionsProfile: {
            type: 'string',
        },
        roamingAllowed: {
            type: 'boolean',
        },
        ueAmbrDl: {
            type: 'number',
            format: 'int64',
        },
        ueAmbrUl: {
            type: 'number',
            format: 'int64',
        },
        ueUsageType: {
            type: 'number',
            format: 'int32',
        },
        externalId: {
            type: 'number',
            format: 'int64',
        },
        deleted: {
            type: 'boolean',
        },
        site: {
            properties: {
            },
        },
        usimSites: {
            type: 'array',
            contains: {
                type: 'UsimSite',
            },
        },
    },
} as const;
