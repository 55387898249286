/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimEventHistory = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        dataVolume: {
            type: 'number',
        },
        duration: {
            type: 'string',
        },
        sessionStart: {
            type: 'string',
            format: 'date-time',
        },
        sessionStatus: {
            type: 'string',
        },
        usimSite: {
            type: 'UsimSite',
        },
    },
} as const;
