/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimSite = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        dateActivated: {
            type: 'string',
            format: 'date-time',
        },
        dateDeactivated: {
            type: 'string',
            format: 'date-time',
        },
        ipAddresses: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        lastPolledAt: {
            type: 'string',
            format: 'date-time',
        },
        status: {
            type: 'UsimStatus',
        },
        description: {
            type: 'string',
        },
        ipAllocations: {
            type: 'array',
            contains: {
                type: 'IpAllocation',
            },
        },
        site: {
            properties: {
            },
        },
        usim: {
            type: 'Usim',
        },
        usimProfile: {
            type: 'UsimProfile',
        },
        usimSiteEventHistories: {
            type: 'array',
            contains: {
                type: 'UsimEventHistory',
            },
        },
        previousStatus: {
            type: 'UsimStatus',
        },
        previousUsimProfile: {
            type: 'UsimProfile',
        },
    },
} as const;
