import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import customerManagement, { initialState, loadState, saveState } from './customerManagement'
import notificationReducer, { initialState as notificationInitialState } from './submitNotification'

// const dev_envs = ['development', 'stg']
const dev_envs = ['development', 'production']


export const appInitialState = {
  customerManagement: initialState,
  notification: notificationInitialState
}

// Load state from localStorage
const initialLoadState = appInitialState;
appInitialState.customerManagement = loadState() || initialState; // Ensure initialState is not undefined

// Define the store with the customerManagement reducer and notification reducer
export const store = configureStore({
  reducer: {
    [customerManagement.name]: customerManagement.reducer,
    notification: notificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: dev_envs.includes(process.env.NEXT_PUBLIC_APP_ENV),
  preloadedState: initialLoadState // Set preloadedState to the loaded state
});

function makeStore() {
  return store;
}

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export const wrapper = createWrapper<AppStore>(makeStore, { debug: process.env.NEXT_PUBLIC_APP_ENV !== 'production' })

store.subscribe(() => {
  // console.log('in subscribe')
  saveState(store.getState());
});

// Dispatch all actions again after setting the initial state
const replayActions = () => {
  // Get the entire state from the store
  const currentState = store.getState();

  // Dispatch all actions again based on the current state
  // This assumes that your actions are pure and replaying them won't have side effects
  // If some actions have side effects, handle them separately
  // For simplicity, this example replays actions by dispatching the same actions with the same payloads
  // You may need to adjust this based on your specific actions and state structure
  // For instance, you may need to dispatch multiple actions to restore the state correctly
  // Make sure to dispatch actions in the correct order
  // You can access individual slices of the state using store.getState().customerManagement, etc.
  // Dispatch actions for each slice of the state as needed
  // Example: store.dispatch(customerManagement.actions.setCustomers(currentState.customerManagement.customers));
  store.dispatch(customerManagement.actions.setUser(currentState.customerManagement.user));
  store.dispatch(customerManagement.actions.setAppSettings(currentState.customerManagement.settings));
};

// replayActions();