/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MetricsEventObject = {
    description: `Metrics event to be published`,
    properties: {
        eventType: {
            properties: {
            },
        },
        customerId: {
            type: 'number',
            format: 'int64',
        },
        siteIds: {
            type: 'array',
            contains: {
                type: 'number',
                format: 'int64',
            },
        },
        simIds: {
            type: 'array',
            contains: {
                type: 'number',
                format: 'int64',
            },
        },
        timestamp: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
