/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Provider = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        type: {
            type: 'ProviderType',
        },
        sites: {
            type: 'array',
            contains: {
                type: 'Site',
            },
        },
    },
} as const;
