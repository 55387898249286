// @ts-expect-error
import { getThemeTokens } from '@telus-uds/components-base'
import paletteAllium from "@telus-uds/palette-allium";
import palette from ".";

export const getStyling = (component: any) => (state: Record<string, any>) =>
  getThemeTokens(component, {}, {}, state);

export const SubTabTheme = {
  tokens: {
    highlightColor: paletteAllium.color.purpleTelus,
    backgroundColor: paletteAllium.color.greyAthens,
    borderColor: paletteAllium.color.greyAthens,
    maxWidth: 'none',
    paddingVertical: paletteAllium.size.size10,
    space: paletteAllium.size.size100,
    color: paletteAllium.color.greyShuttle,
    fontSize: paletteAllium.fontSize.size14,
  }
}

export const TopMenuButtonTheme = {
  tokens: {
    textLine: 'none',
    borderColor: 'transparent',
    color: paletteAllium.color.greyCharcoal,
    fontWeight: 400,
    minWidth: paletteAllium.size.size10,
    borderWidth: '0px',
    validationIconSize: paletteAllium.size.size12,
    paddingLeft: paletteAllium.size.size10,
    paddingRight: paletteAllium.size.size10
  },
  rules: [
    {
      if: {
        focus: true
      },
      tokens: {
        borderRadius: paletteAllium.radius.radius4,
        backgroundColor: paletteAllium.color.greyAthens,
        outerBorderColor: paletteAllium.color.purpleTelus,
        color: paletteAllium.color.purpleTelus
      }
    }
  ]
}
export const tableTopButtonTheme = {
  tokens: {
    color: paletteAllium.color.purpleTelus,
    outerBorderWidth: paletteAllium.border.none,
    fontSize: paletteAllium.fontSize.size16
  },
  rules: [
    {
      if: {
        focus: true
      },
      tokens: {
        outerBorderWidth: paletteAllium.border.border2,
        borderRadius: paletteAllium.radius.radius4,
        backgroundColor: paletteAllium.color.greyAthens,
        outerBorderColor: paletteAllium.color.purpleTelus,
        color: paletteAllium.color.purpleTelus
      }
    }
  ]
}

export const tableTopIconTheme = {
  tokens: {
    color: paletteAllium.color.purpleTelus
  }
}

export const blackHeadingTheme = {
  tokens: {
    color: paletteAllium.color.greyCharcoal,
    fontSize: paletteAllium.fontSize.size24,
    fontWeight: 700
  }
}
export const dropDownTheme = {
  tokens: {
    paddingRight: paletteAllium.size.size8,
    paddingLeft: paletteAllium.size.size8,
    paddingTop: paletteAllium.size.size10,
    paddingBottom: paletteAllium.size.size10
  }
}
export const dropDownItemTheme = {
  tokens: {
    textLine: 'none',
    textAlign: 'left',
    paddingLeft: paletteAllium.size.size10,
    color: paletteAllium.color.greyCharcoal,
    minWidth: 150,
    fontWeight: 400
  },
  rules: [
    {
      if: {
        hover: true
      },
      tokens: {
        color: paletteAllium.color.purpleTelus,
        fontWeight: 700
      }
    },
    {
      if: {
        focus: true
      },
      tokens: {
        color: paletteAllium.color.purpleTelus,
        outerBorderColor: paletteAllium.color.purpleTelus
      }
    }
  ]
}

export const labelTheme = {
  tokens: {
    color: paletteAllium.color.greyCharcoal,
    fontSize: paletteAllium.fontSize.size16,
    fontWeight: 700
  }
}


export const expandedViewTheme = {
  tokens: {
    paddingTop: paletteAllium.size.size0,
    paddingBottom: paletteAllium.size.size0
  }
}

export const disabledTheme = {
  tokens: {
    color: paletteAllium.color.greyShuttle,
    fontSize: paletteAllium.fontSize.size14,
  }
}

export const disabledActionButtonTheme = {
  tokens: {
    textAlign: 'left',
    color: paletteAllium.color.greyCloud,
    fontWeight: 500,
    minWidth: 150
  }
}
export const disabledButtonTheme = {
  tokens: {
    color: paletteAllium.color.white,
    backgroundColor: paletteAllium.color.greyCloud,
    borderWidth: '0px',
    outerBorderWidth: '0px',
    paddingLeft: '35px',
    paddingRight: '35px',
    paddingTop: '8px',
    paddingBottom: '8px'
  }
}
export const successTheme = {
  tokens: {
    color: paletteAllium.color.greenAccessible
  }
}

export const cardTheme = {
  tokens: {
    paddingTop: paletteAllium.size.size16,
    paddingBottom: paletteAllium.size.size16
  }
}

export const loginCardTheme = {
  tokens: {
    paddingTop: paletteAllium.size.size24,
    paddingBottom: paletteAllium.size.size24
  }
}

export const topMenuTheme = {
  tokens: {
    fontSize: paletteAllium.fontSize.size14,
    borderWidth: paletteAllium.border.none,
    paddingRight: paletteAllium.size.size0,
    paddingLeft: paletteAllium.size.size0,
    color: paletteAllium.color.greyCharcoal,
    fontWeight: 400,
    textLine: 'none'
  },
  rules: [
    {
      if: {
        focus: true
      },
      tokens: {
        borderRadius: paletteAllium.radius.radius4,
        backgroundColor: paletteAllium.color.greyAthens,
        outerBorderColor: paletteAllium.color.purpleTelus,
        color: paletteAllium.color.purpleTelus
      }
    }
  ]
}

export const dividerTheme = {
  tokens: {
    color: paletteAllium.color.greyMystic
  }
}

export const buttonDropDownTheme = {
  tokens: {
    color: paletteAllium.color.purpleTelus,
    borderColor: paletteAllium.color.purpleTelus,
    borderWidth: '0px',
    outerBorderWidth: '0px',
    paddingLeft: '35px',
    paddingRight: '35px',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  rules: [
    {
      if: {
        hover: true
      },
      tokens: {
        borderWidth: '0px',
        outerBorderWidth: '0px',
        borderColor: paletteAllium.color.purpleTelus,
      }
    },
    {
      if: {
        focus: true,
      },
      tokens: {
        borderWidth: '0px',
        outerBorderWidth: '0px',
        borderColor: paletteAllium.color.purpleTelus,
      }
    }
  ]
}
