/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $NetworkCommandRequest = {
    properties: {
        networkElement: {
            type: 'NetworkElement',
        },
        commandCode: {
            type: 'CommandCode',
        },
        networkElementHost: {
            type: 'string',
        },
        commandParams: {
            type: 'dictionary',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
