/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Qos4gResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        apnAmbrDl: {
            type: 'number',
            format: 'int64',
        },
        apnAmbrUl: {
            type: 'number',
            format: 'int64',
        },
        extendedApnAmbrDl: {
            type: 'string',
        },
        extendedApnAmbrUl: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        preemptionCapability: {
            type: 'string',
        },
        preemptionVulnerability: {
            type: 'string',
        },
        priority: {
            type: 'number',
            format: 'int32',
        },
        qci: {
            type: 'number',
            format: 'int32',
        },
        vplmnDynamicAddressAllowed: {
            type: 'string',
        },
        siteName: {
            type: 'string',
        },
    },
} as const;
