/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MetricName {
    UP = 'UP',
    SIM_LOADED = 'SIM_LOADED',
    SIM_ACTIVATED = 'SIM_ACTIVATED',
    SIM_DEACTIVATED = 'SIM_DEACTIVATED',
    SIM_DELETED = 'SIM_DELETED',
    APN_CONFIGURED = 'APN_CONFIGURED',
    APN_PROFILE_CONFIGURED = 'APN_PROFILE_CONFIGURED',
    SIM_PROFILE_CONFIGURED = 'SIM_PROFILE_CONFIGURED',
    QOS_3G_CONFIGURED = 'QOS_3G_CONFIGURED',
    QOS_4G_CONFIGURED = 'QOS_4G_CONFIGURED',
    USER_LOGGED_IN = 'USER_LOGGED_IN',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    PROVISIONING_TIME = 'PROVISIONING_TIME',
    ACTIVATION_TIME = 'ACTIVATION_TIME',
    DEACTIVATION_TIME = 'DEACTIVATION_TIME',
    DELETION_TIME = 'DELETION_TIME',
    PROVISIONING_FAILURE = 'PROVISIONING_FAILURE',
    ACTIVATION_FAILURE = 'ACTIVATION_FAILURE',
    DEACTIVATION_FAILURE = 'DEACTIVATION_FAILURE',
    DELETION_FAILURE = 'DELETION_FAILURE',
    HTTP_REQUESTS_ACTIVE = 'HTTP_REQUESTS_ACTIVE',
    HTTP_REQUESTS_TOTAL = 'HTTP_REQUESTS_TOTAL',
}
