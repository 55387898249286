import { FlexGrid, Typography } from '@telus-uds/components-web'
import styles from './Footer.module.scss'
import useServiceVersion from '@/hooks/useServiceVersion'

const Footer = () => {
  const serviceVersion = useServiceVersion()
  const currentYear = new Date().getFullYear()
  return (
    <>
      <div className={styles.footerContainer}>
        <FlexGrid tag="footer" limitWidth={false} className={styles.fullWidthFlexGrid}>
          <FlexGrid.Row horizontalAlign="start" verticalAlign="middle">
            <FlexGrid.Col horizontalAlign="left" md={4}>
              <img className={styles.logo} src="https://images.ctfassets.net/fikanzmkdlqn/3loH0T0nylM0oqQ5CCbfWa/5c6adb84324374887c8ee92c81f0604a/TELUS_LMTFF_EN_Hor_2021_Digital_RGB__2_.svg" alt="TELUS Logo - let's make the future friendly"></img>
            </FlexGrid.Col>
            <FlexGrid.Col horizontalAlign="center" md={4}>
              {serviceVersion && <Typography variant={{ size: 'small' }}>{`Version: ${serviceVersion.api}`}</Typography>}
              </FlexGrid.Col>
            <FlexGrid.Col horizontalAlign="right" md={4}>
              <Typography variant={{ size: 'small' }} >© {currentYear} TELUS</Typography>
            </FlexGrid.Col>
          </FlexGrid.Row>
        </FlexGrid>
      </div>
    </>
  )
}

export default Footer
