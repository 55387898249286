
export interface SystemSettingsConfig {
  CLIENT_ID: string | undefined;
  CLIENT_SECRET: string | undefined;
  SCOPE: string | undefined;
  SSO_LOGIN_URL: string | undefined;
  SSO_TOKEN_URL: string | undefined;
  SSO_LOGOUT_URL: string | undefined;
  authorizationCodeUrl: string;
  logoutUrl: string;
  refreshTokenUrl: string;
  homepageRedirectPath: string;
  isLocalHost: boolean;
}

export default class SystemSettings {
  static settings: SystemSettingsConfig = {
    CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID,
    CLIENT_SECRET: process.env.NEXT_PUBLIC_CLIENT_SECRET,
    SCOPE: process.env.NEXT_PUBLIC_SCOPE,
    SSO_LOGIN_URL: process.env.NEXT_PUBLIC_SSO_LOGIN_URL,
    SSO_TOKEN_URL: process.env.NEXT_PUBLIC_SSO_TOKEN_URL,
    SSO_LOGOUT_URL: process.env.NEXT_PUBLIC_SSO_LOGOUT_URL,
    
    authorizationCodeUrl: '/api/auth/login',
    logoutUrl: '/api/auth/logout',
    refreshTokenUrl: '/api/auth/refresh-token',
    homepageRedirectPath: '/',
    isLocalHost: process.env.NEXT_PUBLIC_IS_LOCALHOST_ENABLE === 'true'
  }

  static updateSettings(partialSettings: Partial<SystemSettingsConfig>) {    
    SystemSettings.settings = {
      ...SystemSettings.settings,
      ...partialSettings
    };    
  }
}
