import App from '@/components/App';

/* Suppress warnings related to UDS component non-breaking conflicts with styled-components. 
*/
if (process.env.NODE_ENV === 'development') {
  const originalError = console.error;
  const knownUDSIssues = [
    'If you intentionally want it to appear in the DOM',
    'Either remove it from the element, or pass a string or number value to keep it in the DOM',
    // add more dom or other UDS bugged
  ];

  console.error = (...args) => {
    if (typeof args[0] === 'string' && knownUDSIssues.some(message => args[0].includes(message))) {
      // Suppress this specific warning
      return;
    }
    originalError(...args);
  };
}

export default App;
