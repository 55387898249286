/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerRequest } from '../models/CreateCustomerRequest';
import type { CustomerContact } from '../models/CustomerContact';
import type { CustomerResponse } from '../models/CustomerResponse';
import type { CustomerUserResponse } from '../models/CustomerUserResponse';
import type { CustomerUsersRequest } from '../models/CustomerUsersRequest';
import type { Page } from '../models/Page';
import type { SiteRequest } from '../models/SiteRequest';
import type { SiteResponse } from '../models/SiteResponse';
import type { UpdateCustomerRequest } from '../models/UpdateCustomerRequest';
import type { UpdateSiteRequest } from '../models/UpdateSiteRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CustomerApiService {
    /**
     * Retrieve detailed information about a specific customer
     * @param customerId
     * @returns CustomerResponse Successfully retrieved customer details
     * @throws ApiError
     */
    public static get(
        customerId: number,
    ): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customer/{customerId}',
            path: {
                'customerId': customerId,
            },
            errors: {
                400: `Bad Request`,
                404: `Customer not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Update existing information for a specific customer
     * @param customerId
     * @param requestBody
     * @returns CustomerResponse Customer updated successfully
     * @throws ApiError
     */
    public static update(
        customerId: number,
        requestBody: UpdateCustomerRequest,
    ): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customer/{customerId}',
            path: {
                'customerId': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Customer not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove a customer record from the system
     * @param customerId
     * @returns void
     * @throws ApiError
     */
    public static delete(
        customerId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customer/{customerId}',
            path: {
                'customerId': customerId,
            },
            errors: {
                400: `Bad Request`,
                404: `Customer not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve detailed information about a specific user under a customer account
     * @param customerId
     * @param userId
     * @returns CustomerUserResponse Successfully retrieved user details
     * @throws ApiError
     */
    public static getUser1(
        customerId: number,
        userId: string,
    ): CancelablePromise<CustomerUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customer/{customerId}/users/{userId}',
            path: {
                'customerId': customerId,
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Update details of an existing user associated with a customer
     * @param customerId
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateUser(
        customerId: number,
        userId: string,
        requestBody: UpdateUserRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customer/{customerId}/users/{userId}',
            path: {
                'customerId': customerId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Update the site assignments for a user
     * @param customerId
     * @param userId
     * @param requestBody
     * @returns any User site assignments updated successfully
     * @throws ApiError
     */
    public static updateUserSiteAssignments(
        customerId: number,
        userId: number,
        requestBody: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customer/{customerId}/users/{userId}/sites',
            path: {
                'customerId': customerId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User or site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Update details of a specific site for a customer
     * @param customerId
     * @param siteId
     * @param formData
     * @returns SiteResponse Site updated successfully
     * @throws ApiError
     */
    public static updateSite(
        customerId: number,
        siteId: number,
        formData?: {
            data: UpdateSiteRequest;
            keystoreFile?: Blob;
        },
    ): CancelablePromise<SiteResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customer/{customerId}/sites/{siteId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                404: `Site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove a site from a customer's record
     * @param customerId
     * @param siteId
     * @returns void
     * @throws ApiError
     */
    public static deleteSite(
        customerId: number,
        siteId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customer/{customerId}/sites/{siteId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                404: `Site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Update a specific contact for a customer
     * @param customerId
     * @param contactId
     * @param requestBody
     * @returns CustomerContact Contact updated successfully
     * @throws ApiError
     */
    public static updateContact(
        customerId: number,
        contactId: number,
        requestBody: CustomerContact,
    ): CancelablePromise<CustomerContact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customer/{customerId}/contacts/{contactId}',
            path: {
                'customerId': customerId,
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Contact not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove a contact from a customer's record
     * @param customerId
     * @param contactId
     * @returns void
     * @throws ApiError
     */
    public static deleteContact(
        customerId: number,
        contactId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customer/{customerId}/contacts/{contactId}',
            path: {
                'customerId': customerId,
                'contactId': contactId,
            },
            errors: {
                400: `Bad Request`,
                404: `Contact not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve a list of all customers
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param id
     * @param name
     * @param ban
     * @param statuses
     * @param searchTerm
     * @returns Page Successfully retrieved list
     * @throws ApiError
     */
    public static list(
        page?: any,
        size?: any,
        sort?: any,
        id?: string,
        name?: string,
        ban?: number,
        statuses?: Array<string>,
        searchTerm?: string,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customer',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'id': id,
                'name': name,
                'ban': ban,
                'statuses': statuses,
                'searchTerm': searchTerm,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Create a new customer record
     * @param keystoreFile
     * @param formData
     * @returns CustomerResponse Customer created successfully
     * @throws ApiError
     */
    public static create(
        keystoreFile: Array<Blob>,
        formData?: {
            data: CreateCustomerRequest;
        },
    ): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customer',
            query: {
                'keystoreFile': keystoreFile,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve a list of all users associated with a specific customer
     * @param customerId
     * @returns any Successfully retrieved list of users
     * @throws ApiError
     */
    public static listUsers1(
        customerId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customer/{customerId}/users',
            path: {
                'customerId': customerId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Add a new user to a customer account
     * @param customerId
     * @param requestBody
     * @returns CustomerUserResponse User created successfully
     * @throws ApiError
     */
    public static createUser1(
        customerId: number,
        requestBody: CustomerUsersRequest,
    ): CancelablePromise<CustomerUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customer/{customerId}/users',
            path: {
                'customerId': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove a user from a customer account
     * @param customerId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static deleteUser1(
        customerId: number,
        requestBody: Array<string>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customer/{customerId}/users',
            path: {
                'customerId': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Add a new site for a specific customer
     * @param customerId
     * @param keystoreFile
     * @param formData
     * @returns any Site added successfully
     * @throws ApiError
     */
    public static addSite(
        customerId: number,
        keystoreFile: Array<Blob>,
        formData?: {
            data: Array<SiteRequest>;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customer/{customerId}/sites',
            path: {
                'customerId': customerId,
            },
            query: {
                'keystoreFile': keystoreFile,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Activate or deactivate a user account
     * @param customerId
     * @param userId
     * @param requestBody
     * @returns any User account status updated successfully
     * @throws ApiError
     */
    public static toggleUserStatus(
        customerId: number,
        userId: number,
        requestBody: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/service/api/v1/customer/{customerId}/users/{userId}/status',
            path: {
                'customerId': customerId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Change the role or access level of a user
     * @param customerId
     * @param userId
     * @param requestBody
     * @returns any User role updated successfully
     * @throws ApiError
     */
    public static changeUserRole(
        customerId: number,
        userId: number,
        requestBody: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/service/api/v1/customer/{customerId}/users/{userId}/role',
            path: {
                'customerId': customerId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve a list of all Providers
     * @returns any Retrieve a list of all Providers
     * @throws ApiError
     */
    public static listProviders(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/providers',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve a list of all timezones
     * @returns Page Successfully retrieved list
     * @throws ApiError
     */
    public static listTimezones(): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customer/timezones',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieve a list of all Customers for displaying in Customer dropdown while creating a User
     * @returns any Retrieve a list of all Customer for displaying in Customer dropdown while creating a User
     * @throws ApiError
     */
    public static getCustomerDropdownList(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customer/dropdown',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
}
