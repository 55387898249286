/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CustomerUserRequest = {
    properties: {
        id: {
            type: 'string',
        },
        username: {
            type: 'string',
            pattern: '^[a-zA-Z][a-zA-Z0-9._-]{1,253}[a-zA-Z0-9]$',
        },
        email: {
            type: 'string',
            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
        },
        name: {
            type: 'string',
            pattern: '^[a-zA-Z0-9 \'\\-\\.éàèùâêîôûëïüçÉÀÈÙÂÊÎÔÛËÏÜÇ]+$',
        },
        phone: {
            type: 'string',
        },
        status: {
            type: 'UserStatus',
        },
        role: {
            type: 'Role',
        },
        language: {
            type: 'string',
        },
        timeZone: {
            type: 'string',
        },
        siteIds: {
            type: 'array',
            contains: {
                type: 'number',
                format: 'int64',
            },
        },
    },
} as const;
