/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateUserRequest = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        email: {
            type: 'string',
            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
        },
        roleId: {
            type: 'string',
            isRequired: true,
        },
        status: {
            type: 'UserStatus',
        },
        phone: {
            type: 'string',
        },
        siteIds: {
            type: 'array',
            contains: {
                type: 'number',
                format: 'int64',
            },
        },
    },
} as const;
