/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimSiteEntryResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        imsi: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        status: {
            type: 'UsimStatus',
        },
        profileName: {
            type: 'string',
        },
        profileId: {
            type: 'number',
            format: 'int64',
        },
        msisdn: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        dateActivated: {
            type: 'string',
            format: 'date-time',
        },
        dateAdded: {
            type: 'string',
            format: 'date-time',
        },
        siteId: {
            type: 'number',
            format: 'int64',
        },
        siteName: {
            type: 'string',
        },
        customerName: {
            type: 'string',
        },
    },
} as const;
