/**
 * This context provides a mechanism to manage bulk operations within the application,
 * allowing you to add and remove bulk operations,
 * which can be tracked and displayed across different components.
 * 
 * Adding to file:
 * import { useBulkOperations } from '@/context/BulkOperationsContext';
 * const { bulkOperations, addBulkOperation, removeBulkOperation } = useBulkOperations();
 * 
 * API:
 * - bulkOperations: An array of current bulk operations.
 * - addBulkOperation(message: string, location: TableType, locationIndex?: number): Adds a new bulk operation to the stack and returns its unique ID.
 * - removeBulkOperation(bulkOperationId: string): Removes a bulk operation by its ID from the stack.
 */

import React, { createContext, useContext, useState, useCallback } from 'react';
import { TableType } from '@/types/Table';

interface BulkOperation {
  bulkOperationId: string;
  message: string;
  location: TableType;
  locationIndex?: number; // Optional field
}

interface BulkOperationsContextType {
  bulkOperations: BulkOperation[];
  addBulkOperation: (message: string, location: TableType, locationIndex?: number) => string;
  removeBulkOperation: (bulkOperationId: string) => void;
  hasOperation: (location: TableType, locationIndex?: number) => boolean;
}

const BulkOperationsContext = createContext<BulkOperationsContextType>({
  bulkOperations: [],
  addBulkOperation: () => '',
  removeBulkOperation: () => {},
  hasOperation: () => false,
});

export const BulkOperationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [bulkOperations, setBulkOperations] = useState<BulkOperation[]>([]);

  const addBulkOperation = useCallback((message: string, location: TableType, locationIndex?: number) => {
    const bulkOperationId = `bulkOp-${new Date().getTime()}`;
    setBulkOperations((prev) => [...prev, { bulkOperationId, message, location, locationIndex }]);
    return bulkOperationId;
  }, []);

  const removeBulkOperation = useCallback((bulkOperationId: string) => {
    setBulkOperations((prev) => prev.filter((op) => op.bulkOperationId !== bulkOperationId));
  }, []);

  const hasOperation = useCallback((location: TableType, locationIndex?: number) => {
    return bulkOperations.some(op => 
      op.location === location && 
      (locationIndex === undefined || op.locationIndex === locationIndex)
    );
  }, [bulkOperations]);

  return (
    <BulkOperationsContext.Provider value={{ bulkOperations, addBulkOperation, removeBulkOperation, hasOperation }}>
      {children}
    </BulkOperationsContext.Provider>
  );
};

export const useBulkOperations = () => {
  return useContext(BulkOperationsContext);
};
