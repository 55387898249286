/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateCustomerRequest = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        customerName: {
            type: 'string',
            isRequired: true,
            pattern: '^[a-zA-Z0-9 \'\\-\\.éàèùâêîôûëïüçÉÀÈÙÂÊÎÔÛËÏÜÇ]+$',
        },
        ban: {
            type: 'number',
            isRequired: true,
            format: 'int64',
            maximum: 9999999999,
            minimum: 1,
        },
        customerStatus: {
            type: 'string',
        },
        shippingInformation: {
            type: 'ShippingInformationRequest',
            isRequired: true,
        },
        billingInformation: {
            type: 'BillingInformationRequest',
            isRequired: true,
        },
        additionalContacts: {
            type: 'array',
            contains: {
                type: 'CustomerContactRequest',
            },
        },
        primaryContact: {
            type: 'CustomerContactRequest',
        },
    },
} as const;
