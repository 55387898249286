/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DeviceSynchronisationResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        startDate: {
            type: 'string',
            format: 'date-time',
        },
        endDate: {
            type: 'string',
            format: 'date-time',
        },
        status: {
            type: 'NetworkProfileStatus',
        },
        executedBy: {
            type: 'string',
        },
        itemsDeleted: {
            type: 'number',
            format: 'int32',
        },
        itemsUpdated: {
            type: 'number',
            format: 'int32',
        },
        itemsCreated: {
            type: 'number',
            format: 'int32',
        },
    },
} as const;
