/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CommandCode {
    GET_MACHINE_HEALTH_STATUS = 'GET_MACHINE_HEALTH_STATUS',
    GET_TECH_REPORT = 'GET_TECH_REPORT',
    GET_SUBSCRIBER_SESSION_INFO_MME = 'GET_SUBSCRIBER_SESSION_INFO_MME',
    GET_SUBSCRIBER_SESSION_INFO_HSS = 'GET_SUBSCRIBER_SESSION_INFO_HSS',
    GET_SUBSCRIBER_SESSION_INFO_PGW = 'GET_SUBSCRIBER_SESSION_INFO_PGW',
    GET_APN_POOL_CONFIG = 'GET_APN_POOL_CONFIG',
    GET_APN_POOL_CONFIG_HSS = 'GET_APN_POOL_CONFIG_HSS',
    GET_SERVER_UPTIME_IN_DAYS = 'GET_SERVER_UPTIME_IN_DAYS',
    GET_NETWORK_CONFIG = 'GET_NETWORK_CONFIG',
    CLEAR_SUBSCRIBER_SESSION = 'CLEAR_SUBSCRIBER_SESSION',
    GET_SUBSCRIBER_COUNT_MME = 'GET_SUBSCRIBER_COUNT_MME',
    GET_SUBSCRIBER_COUNT_HSS = 'GET_SUBSCRIBER_COUNT_HSS',
    GET_SUBSCRIBER_COUNT_PGW = 'GET_SUBSCRIBER_COUNT_PGW',
    GET_TAI = 'GET_TAI',
    GET_APN = 'GET_APN',
    GET_APN_PROFILES = 'GET_APN_PROFILES',
    GET_ALL_RAISED_ALARMS_MME = 'GET_ALL_RAISED_ALARMS_MME',
}
