/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Qos4g = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        apnAmbrDl: {
            type: 'number',
            format: 'int64',
        },
        apnAmbrUl: {
            type: 'number',
            format: 'int64',
        },
        externalId: {
            type: 'number',
            format: 'int64',
        },
        extendedApnAmbrDl: {
            type: 'boolean',
        },
        extendedApnAmbrUl: {
            type: 'boolean',
        },
        name: {
            type: 'string',
        },
        preemptionCapability: {
            type: 'boolean',
        },
        preemptionVulnerability: {
            type: 'boolean',
        },
        priority: {
            type: 'number',
            format: 'int32',
        },
        qci: {
            type: 'number',
            format: 'int32',
        },
        vplmnDynamicAddressAllowed: {
            type: 'boolean',
        },
        deleted: {
            type: 'boolean',
        },
        apnProfile: {
            type: 'array',
            contains: {
                type: 'ApnProfile',
            },
        },
        site: {
            properties: {
            },
        },
    },
} as const;
