/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AthonetEmmeConnectionData = {
    properties: {
        age_of_location_info: {
            type: 'number',
            format: 'int32',
        },
        ecm_state: {
            type: 'string',
        },
        emm_state: {
            type: 'string',
        },
        last_radio_contact: {
            type: 'string',
        },
        uli: {
            type: 'AthonetEmmeConnectionUli',
        },
        imeisv: {
            type: 'string',
        },
    },
} as const;
