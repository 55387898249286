import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
  message: string | null
  type: 'success' | 'error' | 'warning' | 'default'
  timestamp: string
}

export const initialState: NotificationState = {
  message: null,
  type: null,
  timestamp: null
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<{ message: string, type: 'success' | 'error' }>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.timestamp = new Date().toISOString();
    },
    clearNotification: (state) => {
      state.message = null;
      state.type = null;
      state.timestamp = null;
    }
  }
});

export const { setNotification, clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
