/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BulkSimProvisionResponse = {
    properties: {
        successfullRequest: {
            type: 'number',
            format: 'int32',
        },
        failedRequest: {
            type: 'number',
            format: 'int32',
        },
        failedSims: {
            type: 'array',
            contains: {
                type: 'BulkSimProvisionDetails',
            },
        },
    },
} as const;
