/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AthonetEmmeConnectionUli = {
    properties: {
        ecgi: {
            type: 'AthonetEmmeConnectionEcgi',
        },
        tai: {
            type: 'AthonetEmmeConnectionTai',
        },
    },
} as const;
