/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Site = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        address: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        postalCode: {
            type: 'string',
        },
        province: {
            type: 'string',
        },
        siteName: {
            type: 'string',
        },
        hssUrls: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        mmeUrls: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        spgwUrls: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        pwnKeystore: {
            type: 'string',
            format: 'byte',
        },
        pwnKeystorePassword: {
            type: 'string',
        },
        pwnApiUsername: {
            type: 'string',
        },
        pwnApiPassword: {
            type: 'string',
        },
        provider: {
            type: 'Provider',
        },
        advancedQos3gs: {
            type: 'array',
            contains: {
                type: 'AdvancedQos3g',
            },
        },
        apns: {
            type: 'array',
            contains: {
                type: 'Apn',
            },
        },
        apnProfiles: {
            type: 'array',
            contains: {
                type: 'ApnProfile',
            },
        },
        qos3gs: {
            type: 'array',
            contains: {
                type: 'Qos3g',
            },
        },
        qos4gs: {
            type: 'array',
            contains: {
                type: 'Qos4g',
            },
        },
        customer: {
            type: 'Customer',
        },
        usimProfiles: {
            type: 'array',
            contains: {
                type: 'UsimProfile',
            },
        },
        usimSites: {
            type: 'array',
            contains: {
                type: 'UsimSite',
            },
        },
        usimSiteAudits: {
            type: 'array',
            contains: {
                type: 'UsimSiteAudit',
            },
        },
        enableScheduler: {
            type: 'boolean',
        },
        schedulerInterval: {
            type: 'number',
            format: 'int32',
        },
    },
} as const;
