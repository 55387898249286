/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimSessionInfo = {
    properties: {
        apn: {
            type: 'string',
        },
        qci: {
            type: 'number',
            format: 'int32',
        },
        ip: {
            type: 'AthonetPgwIp',
        },
        rat: {
            type: 'string',
        },
        usage: {
            type: 'string',
        },
    },
} as const;
