/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AthonetPgwDefault = {
    properties: {
        qos: {
            type: 'AthonetPgwQos',
        },
    },
} as const;
