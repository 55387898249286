/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Role = {
    properties: {
        id: {
            type: 'string',
        },
        name: {
            type: 'string',
            isRequired: true,
            maxLength: 255,
            minLength: 3,
        },
        label: {
            type: 'string',
            isRequired: true,
            maxLength: 255,
            minLength: 3,
        },
        type: {
            type: 'RoleType',
            isRequired: true,
        },
        order: {
            type: 'number',
            format: 'int32',
        },
    },
} as const;
