import { AppSettings, setAppSettings } from '@/store/customerManagement'
import { disabledTheme, getStyling } from '@/styles/theme/themes'
import { Button, Icon } from '@telus-uds/components-web'
import React from 'react'
import { useDispatch } from 'react-redux'

export interface IconButtonProps{
  theme: any
  iconTheme?: any
  icon?: any
  content: string
  onClick: () => void
  disabled?: boolean
  disableTheme?: any
  testID?: string
  loaderRequired?: boolean
}

const TextIconButton = (props: IconButtonProps) => {
  const disableTheme = props.disableTheme ?? disabledTheme
  const { loaderRequired = true } = props; 
  const dispatch = useDispatch()
  return (
    <Button
      variant={{ text: true, size: 'small' }}
      tokens={props.disabled ? getStyling(disableTheme) : getStyling(props.theme)}
      onClick={() => {
         loaderRequired && dispatch(setAppSettings({isLoading: true} as AppSettings))
         props.onClick()
      }}
      disabled={props.disabled}
      icon={props.icon}
      testID={props.testID}
    >
      {props.content}
    </Button>
  )
}

export default TextIconButton
