import { useIsAutneticated, useUser } from "@/store/customerManagement";
import { topMenuTheme } from "@/styles/theme/themes";
import { StackWrap } from "@telus-uds/components-web";
import { HeadMale } from '@telus-uds/palette-allium/build/web/icons';
import TextIconButton from "../ToolBar/TextIconButton";

interface TopMenuProps{
  handleLoginPage: () => void
}

const TopMenu = ({ handleLoginPage }: TopMenuProps) => {
  const isAuthenticated = useIsAutneticated();
  const user = useUser()

  return (
    <StackWrap space={3} tokens={{ justifyContent: 'flex-end' }}>
       {isAuthenticated && user && <TextIconButton
        theme={topMenuTheme}
        iconTheme={topMenuTheme}
        icon={HeadMale}
        content={`${user?.name}`}
        disabled={true}
        disableTheme={topMenuTheme}
        onClick={() => {
          // console.log('User profile clicked');
          return false;
        }}
      />}
      <TextIconButton
        theme={topMenuTheme}
        iconTheme={topMenuTheme}
        content={isAuthenticated ? "Logout    " : "Login    "}
        onClick={() => {
          handleLoginPage()
        }}
      />
    </StackWrap>
    )
}
export default TopMenu;
