import { Dispatch } from "redux";
import { ApiError } from "../generated";
import { setNotification } from "@/store/submitNotification";
import { store } from "../store";

type ErrorCode = 400 | 401 | 403 | 404 | 409 | 500;

type ErrorKey = ErrorCode | 'default';

type ErrorMessages = Partial<Record<ErrorKey, string>>;

/** These are some default error cases that I think encompass all api calls across the site by default:
 *  401 - user was logged out for inactivity or session expired
 *  403 - user does not have correct role access or lost role access during session
 *  default - some obscure error occurred that we don't have a specific message for
 */
export const defaultErrorMessages: ErrorMessages = {
  401: "Unauthorized. Please login and try again.",
  403: "Forbidden. You do not have permission to access this resource. If you think this is a mistake, please contact administrator.",
  default: "An unexpected error occurred. Please contact administrator."
};

/**
 * Handles and logs API errors.
 *
 * @param error The error object thrown by an API request.
 * @param dispatch The Redux dispatch
 * @param messages An array of custom error messages to display to the user if not provided by api
 */
export function handleApiError(
  error: ApiError,
  dispatch?: Dispatch,
  messages: ErrorMessages = defaultErrorMessages
) {
  // Get and log current user from the store
  const state = store.getState();
  const user = state.customerManagement.user;
  if (user) {
    console.error("Current User:", user);
  } else {
    console.error("No current user found.");
  }

  let notificationMessage: string | null = null;

  if (error instanceof ApiError) {
    console.error(`Error: ${JSON.stringify(error)}`, error); // Stringify the whole error and log the object
    const code = error.status || "not given";
    let reason = error.body?.reason || error.body?.body?.reason || "not given";
    const message = error.body?.message || error.body?.body?.message || "not given";
    const traceId = error.body?.traceId || "not given";
    console.error(`Trace id: ${traceId}`)
    
    // Additional custom handling for known error types
    if (message.includes("Error parsing CSV")) {
      reason = "CSV Parsing Error";
    }

    const details = `
      Code: ${code}
      Reason: ${reason}
      Message: ${message}
    `.trim();

    const specificMessage = messages[error.status as ErrorCode];
    if (specificMessage) {
      notificationMessage = specificMessage;
    } else {
      notificationMessage = details;
    }

    console.log("status:", error.status);
    switch (error.status) {
      case 400:
        console.error('Bad Request:', error.message);
        break;
      case 401:
        console.error('Unauthorized:', error.message);
        break;
      case 403:
        console.error('Forbidden:', error.message);
        break;
      case 404:
        console.error('Not Found:', error.message);
        break;
      case 409:
        console.error('Conflict:', error.message);
        break;
      case 500:
        console.error('Internal Server Error:', error.message);
        break;
      default:
        console.error('An unexpected error occurred:', error);
        if (!specificMessage) {
          notificationMessage = `An unexpected error occurred - Please contact your Administrator\n${details}`;
        }
        break;
    }
  } else {
    console.error('A non-specific error occurred:', error);
    notificationMessage = 'A non-specific error occurred. Please contact administrator.';
  }

  // Dispatch the error message to notification
  if (dispatch && notificationMessage) {
    dispatch(setNotification({ message: notificationMessage, type: 'error' }));
  }
}
