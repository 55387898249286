/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DeviceSearchResponse = {
    properties: {
        summary: {
            type: 'UsimSearchSummaryResponse',
        },
        results: {
            type: 'PageUsimDataTableViewResponse',
        },
    },
} as const;
