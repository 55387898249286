/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CustomerResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        ban: {
            type: 'number',
            format: 'int64',
        },
        currency: {
            type: 'string',
        },
        customerName: {
            type: 'string',
        },
        customerStatus: {
            type: 'string',
        },
        language: {
            type: 'string',
        },
        billingInformation: {
            type: 'BillingInformationResponse',
        },
        shippingInformation: {
            type: 'ShippingInformationResponse',
        },
        sites: {
            type: 'array',
            contains: {
                type: 'SiteResponse',
            },
        },
        additionalContacts: {
            type: 'array',
            contains: {
                type: 'CustomerContactResponse',
            },
        },
        primaryContact: {
            type: 'CustomerContactResponse',
        },
    },
} as const;
