import { useEffect } from 'react';
import { useRouter } from 'next/router';

function useRedirect(targetUrl: string, redirect: boolean) {
  const router = useRouter();

  useEffect(() => {
    if (redirect && router.isReady) {
      console.log('redirecting to login');
      router.replace(targetUrl);
    }
  }, [redirect, router, targetUrl, router.isReady]);
}

export default useRedirect;
