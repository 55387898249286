/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AthonetPgwSession = {
    properties: {
        apn: {
            type: 'string',
        },
        ip: {
            type: 'AthonetPgwIp',
        },
        rat: {
            type: 'string',
        },
        traffic: {
            type: 'AthonetPgwTraffic',
        },
        default: {
            type: 'AthonetPgwDefault',
        },
    },
} as const;
