/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimSiteAudit = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        eventType: {
            type: 'UsimEvent',
        },
        oldValue: {
            type: 'string',
        },
        newValue: {
            type: 'string',
        },
        status: {
            type: 'UsimSiteAuditStatus',
        },
        description: {
            type: 'string',
        },
        usim: {
            type: 'Usim',
        },
        site: {
            type: 'Site',
        },
    },
} as const;
