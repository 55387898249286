/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Result = {
    properties: {
        metric: {
            type: 'Metric',
        },
        value: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        values: {
            type: 'array',
            contains: {
                type: 'array',
                contains: {
                    type: 'string',
                },
            },
        },
    },
} as const;
