/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Usim = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        amsisdn: {
            type: 'string',
        },
        imsAuthenticationScheme: {
            type: 'string',
        },
        imsi: {
            type: 'string',
        },
        'k': {
            type: 'string',
        },
        msisdn: {
            type: 'string',
        },
        namOverS6a: {
            type: 'string',
        },
        namOverS6dGr: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        op: {
            type: 'string',
        },
        opc: {
            type: 'string',
        },
        privateIdentity: {
            type: 'string',
        },
        ratFrequencySelectionPriorityId: {
            type: 'number',
            format: 'int32',
        },
        regionalSubscriptionsProfile: {
            type: 'string',
        },
        sipPassword: {
            type: 'string',
        },
        ueUsageType: {
            type: 'number',
            format: 'int32',
        },
        externalId: {
            type: 'number',
            format: 'int64',
        },
        encrypt: {
            type: 'boolean',
        },
        useKey: {
            type: 'string',
        },
        sku: {
            type: 'string',
        },
        batchNo: {
            type: 'string',
        },
        iccid: {
            type: 'string',
        },
        profileCode: {
            type: 'string',
        },
        pin1: {
            type: 'string',
        },
        puk1: {
            type: 'string',
        },
        pin2: {
            type: 'string',
        },
        puk2: {
            type: 'string',
        },
        encKi: {
            type: 'string',
        },
        encOpc: {
            type: 'string',
        },
        transportKeyIndex: {
            type: 'number',
            format: 'int32',
        },
        usimSites: {
            type: 'array',
            contains: {
                type: 'UsimSite',
            },
        },
        customer: {
            properties: {
            },
        },
        error: {
            type: 'string',
        },
    },
} as const;
