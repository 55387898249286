/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CustomerContactRequest = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        contactName: {
            type: 'string',
            isRequired: true,
            pattern: '^[a-zA-Z0-9 \'\\-\\.éàèùâêîôûëïüçÉÀÈÙÂÊÎÔÛËÏÜÇ]+$',
        },
        contactType: {
            type: 'Type',
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        emailId: {
            type: 'string',
            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
        },
        phone: {
            type: 'string',
            isRequired: true,
            pattern: '^\\d{10}$',
        },
    },
} as const;
