import { store } from "../store";
import SystemSettings from "./system-settings";

export default class AuthService {
  static lock: boolean = false;
  static refreshPromise: Promise<any> | null = null;
  static error: boolean = false;
  static indefiniteWaitPromise: Promise<void> | null = null;
  static async requestNewToken(): Promise<void> {
    // const accessToken = AuthService.getCookie("access_token");
    // const refreshToken = AuthService.getCookie("refresh_token");

    if (AuthService.refreshPromise) {
      return AuthService.refreshPromise;
    }

    AuthService.lock = true;
    AuthService.refreshPromise = (async () => {
      try {
        await fetch(window.location.origin + SystemSettings.settings.refreshTokenUrl)
        .then(async(response) => {
          if (!response || !response.ok) {
            throw new Error('Network response was not ok');
          }
          const user = await response.json();
          store.dispatch({
            type: 'customerManagement/setUser',
            payload: user
          }); 
          AuthService.error = false;
        })
        .catch(async (error) => {
          console.error('Error refreshing the token:' + error);
          AuthService.error = true;
          store.dispatch({
            type: 'notification/setNotification',
            payload: { message: "Your session has expired. You will be redirected to the login page.", type: 'error' }
          }); 
          await setTimeout(() => {
            store.dispatch({
              type: 'customerManagement/setUser',
              payload: null
            });
          }, 3000);
          return error;
        }).finally(() => {
          AuthService.lock = false;
          AuthService.refreshPromise = null;
        });
        return AuthService.refreshPromise; 
      } catch (error) {
        console.error('Error refreshing token:', error);
      } finally {
        AuthService.lock = false;
        AuthService.refreshPromise = null;
      }
    })();

    return AuthService.refreshPromise;
  }

}