import { dividerTheme, getStyling } from '@/styles/theme/themes';
import { Divider, FlexGrid, Typography } from '@telus-uds/components-web';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <>
      <div className={styles.headerContainer}>
        <FlexGrid tag="header" limitWidth={false}>
          <Divider variant={{ weight: '1px' }} tokens={{ color: 'rgb(216, 216, 216)' }} />
          <FlexGrid.Row horizontalAlign="start" verticalAlign="middle" >
            <img className={styles.logo} src='https://images.ctfassets.net/fikanzmkdlqn/24Npa1YcWK80UQw5IZQunH/1ea5fdb2d1ee84b212ce778e713ab9f4/TELUS_Logo.svg' alt="TELUS Logo"></img>
          </FlexGrid.Row>
        </FlexGrid>
        <Divider tokens={getStyling(dividerTheme)} />
      </div>
    </>
  )
}

export default Header
