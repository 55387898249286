/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetricName } from '../models/MetricName';
import type { MetricsEventObject } from '../models/MetricsEventObject';
import type { PrometheusResponse } from '../models/PrometheusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MetricsService {
    /**
     * Publish a metrics event
     * Publish a metrics event to be processed
     * @param requestBody
     * @returns any Metrics event published successfully
     * @throws ApiError
     */
    public static publishMetrics(
        requestBody: MetricsEventObject,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/metrics/publish',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get metrics
     * Retrieve metrics by name
     * @param metricName Name of the metric
     * @returns any Metrics retrieved successfully
     * @throws ApiError
     */
    public static getMetrics(
        metricName: MetricName,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/{metricName}',
            path: {
                'metricName': metricName,
            },
            errors: {
                400: `Bad Request`,
                404: `Metrics not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get site level metrics
     * Retrieve site level metrics by name
     * @param metricName Name of the metric
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns any Site level metrics retrieved successfully
     * @throws ApiError
     */
    public static getSiteLevelMetrics(
        metricName: MetricName,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/{metricName}/site',
            path: {
                'metricName': metricName,
            },
            query: {
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Bad Request`,
                404: `Metrics not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get metrics by site ID
     * Retrieve metrics by site ID
     * @param metricName Name of the metric
     * @param siteId Site ID
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns any Metrics by site ID retrieved successfully
     * @throws ApiError
     */
    public static getMetricsBySiteId(
        metricName: MetricName,
        siteId: number,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/{metricName}/site/{siteId}',
            path: {
                'metricName': metricName,
                'siteId': siteId,
            },
            query: {
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Bad Request`,
                404: `Metrics not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get customer level metrics
     * Retrieve customer level metrics by name
     * @param metricName Name of the metric
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns any Customer level metrics retrieved successfully
     * @throws ApiError
     */
    public static getCustomerLevelMetrics(
        metricName: MetricName,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/{metricName}/customer',
            path: {
                'metricName': metricName,
            },
            query: {
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Bad Request`,
                404: `Metrics not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get metrics by customer ID
     * Retrieve metrics by customer ID
     * @param metricName Name of the metric
     * @param customerId Customer ID
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns any Metrics by customer ID retrieved successfully
     * @throws ApiError
     */
    public static getMetricsByCustomerId(
        metricName: MetricName,
        customerId: number,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/{metricName}/customer/{customerId}',
            path: {
                'metricName': metricName,
                'customerId': customerId,
            },
            query: {
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Bad Request`,
                404: `Metrics not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get aggregated metrics
     * Retrieve aggregated metrics by name
     * @param metricName Name of the metric
     * @returns number Aggregated metrics retrieved successfully
     * @throws ApiError
     */
    public static getAggregatedMetrics(
        metricName: MetricName,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/{metricName}/aggregated',
            path: {
                'metricName': metricName,
            },
            errors: {
                400: `Bad Request`,
                404: `Metrics not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Query time series data for a metric
     * Query time series data for a specific metric
     * @param metricName Metric name
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns PrometheusResponse Time series data retrieved successfully
     * @throws ApiError
     */
    public static queryTimeSeries(
        metricName: MetricName,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<PrometheusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/query/timeseries',
            query: {
                'metricName': metricName,
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Query the sum of a metric over a range
     * Query the sum value of a metric over a time range
     * @param metricName Metric name
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns PrometheusResponse Sum metric value retrieved successfully
     * @throws ApiError
     */
    public static querySum(
        metricName: MetricName,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<PrometheusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/query/sum',
            query: {
                'metricName': metricName,
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Query a metric over a range
     * Query the value of a metric over a time range
     * @param metricName Metric name
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns PrometheusResponse Metric values retrieved successfully
     * @throws ApiError
     */
    public static queryRange(
        metricName: MetricName,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<PrometheusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/query/range',
            query: {
                'metricName': metricName,
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Query multiple metrics over a range
     * Query the values of multiple metrics over a time range
     * @param metricNames Metric names
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns PrometheusResponse Metric values retrieved successfully
     * @throws ApiError
     */
    public static queryMultiple(
        metricNames: Array<MetricName>,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<PrometheusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/query/multiple',
            query: {
                'metricNames': metricNames,
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Query an instant metric
     * Query the current value of a metric
     * @param metricName Metric name
     * @returns PrometheusResponse Metric value retrieved successfully
     * @throws ApiError
     */
    public static queryInstant(
        metricName: MetricName,
    ): CancelablePromise<PrometheusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/query/instant',
            query: {
                'metricName': metricName,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Query the average of a metric over a range
     * Query the average value of a metric over a time range
     * @param metricName Metric name
     * @param start The start time of the query range in seconds since the Unix epoch
     * @param end The end time of the query range in seconds since the Unix epoch.
     * @param step Step size in seconds
     * @returns PrometheusResponse Average metric value retrieved successfully
     * @throws ApiError
     */
    public static queryAverage(
        metricName: MetricName,
        start?: number,
        end?: number,
        step: number = 3600,
    ): CancelablePromise<PrometheusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/query/average',
            query: {
                'metricName': metricName,
                'start': start,
                'end': end,
                'step': step,
            },
            errors: {
                400: `Invalid input`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get all metrics names
     * Retrieve all metrics names
     * @returns any Metrics names retrieved successfully
     * @throws ApiError
     */
    public static getAllMetricsNames(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/metrics/',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
}
