/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ShippingInformation = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        address: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        province: {
            type: 'string',
        },
        country: {
            type: 'string',
        },
        postalCode: {
            type: 'string',
        },
        customer: {
            properties: {
            },
        },
    },
} as const;
