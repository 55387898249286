/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimProfileResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        apnProfileId: {
            type: 'number',
            format: 'int64',
        },
        callingPartyCategory: {
            type: 'string',
        },
        chargingCharacteristics: {
            type: 'number',
            format: 'int64',
        },
        csgList: {
            type: 'string',
        },
        defaultApnProfile: {
            type: 'string',
        },
        exceptForPlmns: {
            type: 'string',
        },
        extendedUeAmbrDl: {
            type: 'boolean',
        },
        extendedUeAmbrUl: {
            type: 'boolean',
        },
        namOverS6a: {
            type: 'string',
        },
        namOverS6dGr: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        ratFrequencySelectionPriorityId: {
            type: 'number',
            format: 'int32',
        },
        regionalSubscriptionsProfile: {
            type: 'string',
        },
        roamingAllowed: {
            type: 'string',
        },
        ueAmbrDl: {
            type: 'number',
            format: 'int64',
        },
        ueAmbrUl: {
            type: 'number',
            format: 'int64',
        },
        ueUsageType: {
            type: 'number',
            format: 'int32',
        },
        siteName: {
            type: 'string',
        },
        isDefault: {
            type: 'boolean',
        },
        apns: {
            type: 'array',
            contains: {
                type: 'ApnResponse',
            },
        },
        apnProfiles: {
            type: 'array',
            contains: {
                type: 'ApnProfileResponse',
            },
        },
    },
} as const;
