import '@telus-uds/palette-allium/build/web/fonts/fonts-cdn.css'
import paletteAllium from "@telus-uds/palette-allium";
import { NextApp } from '@telus/web-app-core'
import { BaseProvider, Spacer } from '@telus-uds/components-web'
import alliumTheme from '@telus-uds/theme-allium'
import { AppProps } from 'next/app'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import AltHeader from '@/components/Header/AltHeader'
import { wrapper } from '@/store/index'
import { Provider } from 'react-redux'
// @ts-expect-error
import NoSSR from 'react-no-ssr';
import styled from 'styled-components'
import Head from 'next/head'
import { useRouter } from 'next/router';
import APISubmit from '@/components/APIResponse';
import LoggedInWrapper from '@/components/ToolBar/LoggedInWrapper';
import { BulkOperationsProvider } from '@/context/BulkOperationsContext'; // Import the BulkOperationsProvider

const StyledMain = styled.main`
  position:relative;
  min-height: 100vh;
`;

const StyledLogin = styled.main`
  position:relative;
  min-height: 100vh;
`;

const StyledApp = styled.div`
  position: relative;
  padding: ${paletteAllium.size.size14}px ${paletteAllium.size.size140}px ${paletteAllium.size.size120}px ${paletteAllium.size.size140}px;
  & div{
    z-index: 1;  //inorder to prevent stacking context
  }
  @media screen and (max-width: 1166px) {
    padding: ${paletteAllium.size.size8}px ${paletteAllium.size.size16}px ${paletteAllium.size.size140}px;
  }
`

export function withAllium(App: NextApp): NextApp {
  return function AlliumApp(appProps: AppProps) {
    const { store, props } = wrapper.useWrappedStore(appProps);
    const router = useRouter();
    const isUnprotected = router.pathname === '/login' || router.pathname.includes('/reset-password') || router.pathname.includes('/2fa')
    // console.log("login test: path:", router.pathname + ", isLoginPage:" + isUnprotected)

    return (
      <>
        <Head>
          <title>TELUS CMP</title>
        </Head>
        <Provider store={store}>
          <BaseProvider defaultTheme={alliumTheme}>
            <NoSSR>
              <BulkOperationsProvider>
                {isUnprotected
                  ? <StyledLogin>
                    <AltHeader />
                    <StyledApp>
                      <APISubmit />
                      <App {...props} />
                    </StyledApp>
                    <Footer />
                  </StyledLogin>
                  : <StyledMain>
                    <Header />
                    <LoggedInWrapper>
                      <StyledApp>
                        <APISubmit />
                        <App {...props} />
                        <Spacer space={6} />
                      </StyledApp>
                    </LoggedInWrapper>
                    <Footer />
                  </StyledMain>
                }
              </BulkOperationsProvider>
            </NoSSR>
          </BaseProvider>
        </Provider>
      </>
    )
  }
}
