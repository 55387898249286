/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Qos3gResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        preemptionCapability: {
            type: 'string',
        },
        preemptionVulnerability: {
            type: 'string',
        },
        priority: {
            type: 'number',
            format: 'int32',
        },
        vplmnDynamicAddressAllowed: {
            type: 'string',
        },
        advancedQos3gName: {
            type: 'string',
        },
        advancedQos3gId: {
            type: 'number',
            format: 'int64',
        },
        siteName: {
            type: 'string',
        },
    },
} as const;
