/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateSiteRequest = {
    properties: {
        siteName: {
            type: 'string',
            pattern: '^[a-zA-Z0-9 ]+$',
        },
        address: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        postalCode: {
            type: 'string',
            pattern: '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d$',
        },
        province: {
            type: 'string',
        },
        pwnApiUsername: {
            type: 'string',
            isRequired: true,
        },
        pwnApiPassword: {
            type: 'string',
            isRequired: true,
        },
        pwnKeystoreFile: {
            type: 'binary',
            format: 'binary',
        },
        pwnKeystorePassword: {
            type: 'string',
            isRequired: true,
        },
        hssUrls: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        mmeUrls: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        spgwUrls: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        schedulerInterval: {
            type: 'number',
            format: 'int32',
        },
        schedulerStartTime: {
            type: 'string',
            format: 'date-time',
        },
        enableScheduler: {
            type: 'boolean',
        },
        pwnKeystore: {
            type: 'string',
            format: 'byte',
        },
    },
} as const;
