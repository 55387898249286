/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BillingInformationResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        address: {
            type: 'string',
        },
        billable: {
            type: 'boolean',
        },
        city: {
            type: 'string',
        },
        postalCode: {
            type: 'string',
        },
        province: {
            type: 'string',
        },
        country: {
            type: 'string',
        },
    },
} as const;
