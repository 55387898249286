export enum UserRole {
  CUSTOMER_ADMIN = 'Customer_Admin',
  CUSTOMER_EDITOR = 'Customer_Editor',
  CUSTOMER_VIEWER = 'Customer_Viewer',
  CUSTOMER_SITE_EDITOR = 'Site_Editor',
  CUSTOMER_SITE_VIEWER = 'Site_Viewer',
  TELUS_SYSTEM_ADMIN = '23278_PWN-CMP_Admin',
  TELUS_SYSTEM_EDITOR = '23278_PWN-CMP_Editor',
  TELUS_SYSTEM_VIEWER = '23278_PWN-CMP_Viewer',

}

export enum GroupRole {
  ANY_TELUS_ADMIN,
  ANY_TELUS,
  ANY_CUSTOMER,
  ANY_TELUS_EDITOR,
  ANY_ADMIN,
}

export const getUserRolesByGroup = (group: GroupRole | UserRole): UserRole[] => {
  if (isGroupRole(group)) {
  switch (group) {
    case GroupRole.ANY_TELUS_ADMIN:
      return [
        UserRole.TELUS_SYSTEM_ADMIN,
      ];
    case GroupRole.ANY_TELUS:
      return [
        UserRole.TELUS_SYSTEM_ADMIN,
        UserRole.TELUS_SYSTEM_EDITOR,
        UserRole.TELUS_SYSTEM_VIEWER,
      ];
    case GroupRole.ANY_CUSTOMER:
      return [
        UserRole.CUSTOMER_ADMIN,
        UserRole.CUSTOMER_EDITOR,
        UserRole.CUSTOMER_VIEWER,
        UserRole.CUSTOMER_SITE_EDITOR,
        UserRole.CUSTOMER_SITE_VIEWER,
      ];
    case GroupRole.ANY_TELUS_EDITOR:
      return [
        UserRole.TELUS_SYSTEM_ADMIN,
        UserRole.TELUS_SYSTEM_EDITOR,
      ];
    case GroupRole.ANY_ADMIN:
      return [
        UserRole.TELUS_SYSTEM_ADMIN,
        UserRole.CUSTOMER_ADMIN,
      ];
    default:
      return [];
  }
} else {
  return [group];
}
};

const isGroupRole = (group: GroupRole | UserRole): group is GroupRole => {
  return (group as GroupRole) in GroupRole;
};


export enum RoleType {
  CUSTOMER = 'CUSTOMER',
  TELUS = 'TELUS',
}

export const ROLES_TELUS_ORDERED: UserRole[] = [
  UserRole.TELUS_SYSTEM_ADMIN,
  UserRole.TELUS_SYSTEM_EDITOR,
  UserRole.TELUS_SYSTEM_VIEWER
];

export const ROLES_CUSTOMER_ORDERED: UserRole[] = [
  UserRole.CUSTOMER_ADMIN,
  UserRole.CUSTOMER_EDITOR,
  UserRole.CUSTOMER_VIEWER,
  UserRole.CUSTOMER_SITE_EDITOR,
  UserRole.CUSTOMER_SITE_VIEWER
];