/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimEventHistoryListResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        usimEventHistory: {
            type: 'array',
            contains: {
                type: 'UsimEventHistoryResponse',
            },
        },
    },
} as const;
