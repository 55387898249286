/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AthonetEmmeConnectionResponse } from '../models/AthonetEmmeConnectionResponse';
import type { AthonetEmmeEventHistoryResponse } from '../models/AthonetEmmeEventHistoryResponse';
import type { AthonetPgwResponse } from '../models/AthonetPgwResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AthonetSessionService {
    /**
     * @param simId
     * @returns AthonetPgwResponse OK
     * @throws ApiError
     */
    public static getAthonetPgwResponsePage(
        simId: number,
    ): CancelablePromise<AthonetPgwResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/test/pgw/mogw/imsis/{simId}/status',
            path: {
                'simId': simId,
            },
        });
    }
    /**
     * @returns AthonetEmmeEventHistoryResponse OK
     * @throws ApiError
     */
    public static getAthonetEmmeEventHistory(): CancelablePromise<AthonetEmmeEventHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/test/emme/event/API/mogw/events',
        });
    }
    /**
     * @param simId
     * @returns AthonetEmmeConnectionResponse OK
     * @throws ApiError
     */
    public static getAthonetEmmeResponsePage(
        simId: number,
    ): CancelablePromise<AthonetEmmeConnectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/test/emme/connection/mogw/imsis/{simId}/status',
            path: {
                'simId': simId,
            },
        });
    }
}
