import { setAllowedCustomerUserRoles, setAllowedTelusUserRoles, setCustomerUserRoles, setTelusUserRoles, useAllowedTelusUserRoles, useIsAutneticated, useUser } from "@/store/customerManagement";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomerApiService, OpenAPI, Role, UserApiService } from '@/api/index';
import { RoleType } from "@/types/UserRole";
import { getAllowedRolesFor } from "@/types/RoleMapping";
import { handleApiError } from "@/utils/error-handler";

export const useFetchRoles = () => {
  const dispatch = useDispatch();
  const allowedTelusUserRoles: Role[] = useAllowedTelusUserRoles();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useIsAutneticated();

  const user = useUser();

  useEffect(() => {
    if(!user) {
      return;
    }


    if(!allowedTelusUserRoles || !allowedTelusUserRoles.length && isAuthenticated) {
      // Fetch roles from API and set them in Redux store
      const fetchRoles = async () => {
        try {
          setIsLoading(true);
          const roles: Role[] = await UserApiService.listRoles("ALL");
          const telusUserRoles = roles.filter(role => role.type === RoleType.TELUS);
          const customerUserRoles = roles.filter(role => role.type === RoleType.CUSTOMER);
          dispatch(setCustomerUserRoles(customerUserRoles));
          dispatch(setTelusUserRoles(telusUserRoles));
          dispatch(setAllowedCustomerUserRoles(getAllowedRolesFor(customerUserRoles, user.role, RoleType.CUSTOMER)));
          dispatch(setAllowedTelusUserRoles(getAllowedRolesFor(telusUserRoles, user.role, RoleType.TELUS)));
          setIsLoading(false);
        } catch (error) {
          console.error(error)
          setIsLoading(false);
        }
      };

      // Fetch roles and then check user role
      fetchRoles();
      console.log('role',allowedTelusUserRoles)
    }
  }, []);
}
