/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CustomerDropdownResponse = {
    properties: {
        customerId: {
            type: 'number',
            format: 'int64',
        },
        customerName: {
            type: 'string',
        },
        sites: {
            type: 'array',
            contains: {
                type: 'SiteDropdownResponse',
            },
        },
    },
} as const;
