/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Customer = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        ban: {
            type: 'number',
            format: 'int64',
        },
        currency: {
            type: 'string',
        },
        customerName: {
            type: 'string',
        },
        customerStatus: {
            type: 'string',
        },
        language: {
            type: 'string',
        },
        billingInformation: {
            type: 'BillingInformation',
        },
        shippingInformation: {
            type: 'ShippingInformation',
        },
        customerContacts: {
            type: 'array',
            contains: {
                type: 'CustomerContact',
            },
        },
        usims: {
            type: 'array',
            contains: {
                type: 'Usim',
            },
        },
        sites: {
            type: 'array',
            contains: {
                type: 'Site',
            },
        },
    },
} as const;
