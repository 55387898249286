/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimConnectionStatusResponse = {
    properties: {
        ageOfLocationInfo: {
            type: 'number',
            format: 'int64',
        },
        ecmState: {
            type: 'string',
        },
        emmState: {
            type: 'string',
        },
        lastRadioContact: {
            type: 'string',
        },
        eci: {
            type: 'string',
        },
        plmn: {
            type: 'string',
        },
        imei: {
            type: 'string',
        },
        tac: {
            type: 'string',
        },
    },
} as const;
