/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApnProfile = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        chargingCharacteristics: {
            type: 'number',
            format: 'int64',
        },
        interworking5gsIndicator: {
            type: 'boolean',
        },
        name: {
            type: 'string',
        },
        pgwStaticAllocationProfile: {
            type: 'string',
        },
        externalId: {
            type: 'number',
            format: 'int64',
        },
        deleted: {
            type: 'boolean',
        },
        apn: {
            type: 'Apn',
        },
        qos3g: {
            type: 'Qos3g',
        },
        qos4g: {
            type: 'Qos4g',
        },
        site: {
            properties: {
            },
        },
    },
} as const;
