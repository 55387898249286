import { User } from '@/store/customerManagement';
import { Role } from '../generated';
import { GroupRole, ROLES_CUSTOMER_ORDERED, ROLES_TELUS_ORDERED, UserRole, RoleType, getUserRolesByGroup } from './UserRole';


const convertStringToUserRole = (roleStr: string): UserRole => {
  return Object.values(UserRole).find(role => role === roleStr) as UserRole;
}

const getMatchingRoles = (allRoles: Role[], allowedUserRoles: UserRole[]): Role[] => {
  return allRoles.filter(role => allowedUserRoles.includes(role.name as UserRole));
};

export const getAllowedRolesFor = (allRoles: Role[], userRole: Role, type: RoleType): Role[] => {
    let startIndex;
    let allowedUserRoles: UserRole[];
    if(userRole.type === RoleType.CUSTOMER && type === RoleType.CUSTOMER) {
      startIndex = ROLES_CUSTOMER_ORDERED.indexOf(convertStringToUserRole(userRole.name));
      allowedUserRoles = ROLES_CUSTOMER_ORDERED.slice(startIndex) || [];
    } else {
      startIndex = ROLES_TELUS_ORDERED.indexOf(convertStringToUserRole(userRole.name));
      allowedUserRoles = ROLES_TELUS_ORDERED.slice(startIndex) || [];
    }

    return getMatchingRoles(allRoles, allowedUserRoles) || [];
}

export const hasRoleWith = (roles: Role[] | undefined, label: string | undefined): boolean => {
  return roles?.some(role => role.label === label) ?? false;
};


export const isAnyAdminRole = (role: string): boolean => {
  const allAdminRoles: string[] =[UserRole.TELUS_SYSTEM_ADMIN, UserRole.CUSTOMER_ADMIN];
  return allAdminRoles.includes(role);
}

export const isAnyViewerRole = (role: string): boolean => {
  const allViewerRoles: string[] =[UserRole.TELUS_SYSTEM_VIEWER, UserRole.CUSTOMER_VIEWER, UserRole.CUSTOMER_SITE_VIEWER];
  return allViewerRoles.includes(role);
}

export const isAnyTelusEditor = (role: string): boolean => {
  const allTelusEditorRoles: string[] =[UserRole.TELUS_SYSTEM_ADMIN, UserRole.TELUS_SYSTEM_EDITOR];
  return allTelusEditorRoles.includes(role);
}

export const isAnyEditor = (role: string): boolean => {
  const allTelusEditorRoles: string[] =[UserRole.TELUS_SYSTEM_ADMIN, UserRole.TELUS_SYSTEM_EDITOR, UserRole.CUSTOMER_ADMIN];
  return allTelusEditorRoles.includes(role);
}

export const hasUserRole = (userRole: string, requiredRole: GroupRole | UserRole): boolean => {
  return getUserRolesByGroup(requiredRole).includes(userRole as UserRole);
};

export const isSystemEditor = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.TELUS_SYSTEM_EDITOR
};

export const isSystemViewer = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.TELUS_SYSTEM_VIEWER
};

export const isCustomerAdmin = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.CUSTOMER_ADMIN
};

export const isCustomerEditor = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.CUSTOMER_EDITOR
};

export const isCustomerViewer = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.CUSTOMER_VIEWER
};

export const hasCustomerRole = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.CUSTOMER_ADMIN || currentUserRole?.name === UserRole.CUSTOMER_EDITOR || currentUserRole?.name === UserRole.CUSTOMER_VIEWER
};

export const isTelusAdmin = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.TELUS_SYSTEM_ADMIN
};

export const isSiteEditor = (currentUserRole: string) => {
  return currentUserRole === UserRole.CUSTOMER_SITE_EDITOR
};

export const isSiteViewer = (currentUserRole: string) => {
  return currentUserRole === UserRole.CUSTOMER_SITE_VIEWER
};

export const hasSiteRole = (currentUserRole: Role) => {
  return currentUserRole?.name === UserRole.CUSTOMER_SITE_EDITOR || currentUserRole?.name === UserRole.CUSTOMER_SITE_VIEWER
};
