/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AthonetEmmeConnectionResponse = {
    properties: {
        emme: {
            type: 'AthonetEmmeConnection',
        },
    },
} as const;
