/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Metric = {
    properties: {
        get__name__: {
            type: 'string',
        },
        customerId: {
            type: 'string',
        },
        siteIds: {
            type: 'string',
        },
        simIds: {
            type: 'string',
        },
        instance: {
            type: 'string',
        },
        job: {
            type: 'string',
        },
    },
} as const;
