/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UsimSearchSummaryResponse = {
    properties: {
        totalRecords: {
            type: 'number',
            format: 'int64',
        },
        totalProvisioned: {
            type: 'number',
            format: 'int64',
        },
        totalActivated: {
            type: 'number',
            format: 'int64',
        },
        totalBlocked: {
            type: 'number',
            format: 'int64',
        },
        totalDeactivated: {
            type: 'number',
            format: 'int64',
        },
        totalInventory: {
            type: 'number',
            format: 'int64',
        },
        totalUniqueSims: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
