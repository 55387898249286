/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NetworkElement {
    HSS = 'HSS',
    MME = 'MME',
    PGW = 'PGW',
    PCRF = 'PCRF',
}
