/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApnProfileResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        chargingCharacteristics: {
            type: 'number',
            format: 'int64',
        },
        interworking5gsIndicator: {
            type: 'boolean',
        },
        apnId: {
            type: 'number',
            format: 'int64',
        },
        apnName: {
            type: 'string',
        },
        qos3gId: {
            type: 'number',
            format: 'int64',
        },
        qos3gName: {
            type: 'string',
        },
        qos4gId: {
            type: 'number',
            format: 'int64',
        },
        qos4gName: {
            type: 'string',
        },
        siteName: {
            type: 'string',
        },
    },
} as const;
