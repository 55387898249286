/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $IpAllocation = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        imei: {
            type: 'string',
        },
        imsi: {
            type: 'string',
        },
        sessionCount: {
            type: 'number',
            format: 'int32',
        },
        sessions: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        usimSite: {
            type: 'UsimSite',
        },
    },
} as const;
