/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PageableObject = {
    properties: {
        pageNumber: {
            type: 'number',
            format: 'int32',
        },
        pageSize: {
            type: 'number',
            format: 'int32',
        },
        offset: {
            type: 'number',
            format: 'int64',
        },
        sort: {
            type: 'array',
            contains: {
                type: 'SortObject',
            },
        },
        paged: {
            type: 'boolean',
        },
        unpaged: {
            type: 'boolean',
        },
    },
} as const;
