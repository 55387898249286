import useAutoLogout from '@/hooks/useAutoLogout';
import { useFetchRoles } from '@/hooks/useFetchRoles';
import useRedirect from '@/hooks/useRedirect';
import { useIsAutneticated, useUser, useUserTokenExpiryTime } from '@/store/customerManagement';
import { dividerTheme, getStyling } from '@/styles/theme/themes';
import { checkTokenExpiration, getRedirectUrl, logoutUser } from '@/utils/utils';
import { Divider, Link, Spacer, StackView, StackWrap, Typography } from '@telus-uds/components-web';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Header.module.scss';
import TopMenu from './TopMenu';
import SystemSettings from '@/service/system-settings';

const Header = () => {
  const user = useUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAutneticated();
  const isLocalHost = SystemSettings.settings.isLocalHost;
  const href = getRedirectUrl(user);
  console.log("isLocalHost: ", isLocalHost);

  useRedirect('/login', !isAuthenticated && !isLocalHost);


  useFetchRoles();

  const handleLoginPage = () => {
    if (isAuthenticated) {
      handleLogout();
    } else {
      router.replace('/login');
    }
  }

  const handleLogout = async () => {
    const resp = await logoutUser(dispatch, user);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <StackView>
          <TopMenu handleLoginPage={handleLoginPage} />
          <Divider tokens={getStyling(dividerTheme)} />
          <Spacer space={3} />
          <StackWrap space={2} direction="row" tokens={{ alignItems: "center" }} className={styles.logoContainer}>
            <Link href={href}>
              <img className={styles.logo_main} src='https://images.ctfassets.net/fikanzmkdlqn/24Npa1YcWK80UQw5IZQunH/1ea5fdb2d1ee84b212ce778e713ab9f4/TELUS_Logo.svg' alt="TELUS Logo"></img>
            </Link>
            <Spacer space={5} />
            <Typography variant={{ size: 'h3' }} >Connectivity Management Platform</Typography>
          </StackWrap>
          <Spacer space={3} />
          <Divider tokens={getStyling(dividerTheme)} />
        </StackView>
      </div>
    </>
  )
}
export default Header
