/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdvancedQos3gResponse = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        delay: {
            type: 'number',
            format: 'int32',
        },
        deliveryErrorSdu: {
            type: 'number',
            format: 'int32',
        },
        deliveryOrder: {
            type: 'number',
            format: 'int32',
        },
        guaranteedDl: {
            type: 'number',
            format: 'int64',
        },
        guaranteedUl: {
            type: 'number',
            format: 'int64',
        },
        maxBitRateDl: {
            type: 'number',
            format: 'int64',
        },
        maxBitRateUl: {
            type: 'number',
            format: 'int64',
        },
        maxSduSize: {
            type: 'number',
            format: 'int32',
        },
        mean: {
            type: 'number',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        peak: {
            type: 'number',
            format: 'int64',
        },
        precedence: {
            type: 'number',
            format: 'int32',
        },
        reliability: {
            type: 'number',
            format: 'int32',
        },
        residualBer: {
            type: 'number',
            format: 'int32',
        },
        sduErrorRatio: {
            type: 'number',
            format: 'int32',
        },
        signallingIndication: {
            type: 'string',
        },
        sourceStatisticDescriptor: {
            type: 'number',
            format: 'int32',
        },
        trafficClass: {
            type: 'string',
        },
        trafficDelay: {
            type: 'number',
            format: 'int32',
        },
        trafficPriority: {
            type: 'number',
            format: 'int32',
        },
        siteName: {
            type: 'string',
        },
    },
} as const;
